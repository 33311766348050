import jQuery from 'jquery';

window.jQuery = jQuery;

require('./jquery.serializeToJSON');

window.$ = jQuery;

require('jquery-ui/ui/widgets/autocomplete.js');

require('./bootstrap');

require('bootstrap');

//require('slick-carousel');

require('alpinejs');

//require('jquery-colorbox');

require('google-maps');

//require('shuffle');

//require('slick');

require('./api_search');

require('./app_form_manager');
