window.set_form_value_by_data = function (
  data,
  skipDataIsSetCheck = false,
  formNameForSetFormData = "addEditOnFlyForm"
) {
  $.each(data, function (key, value) {
    if (
      !$(
        "form#" + formNameForSetFormData + ' input[name="' + key + '"]'
      ).val() ||
      skipDataIsSetCheck
    ) {
      $("form#" + formNameForSetFormData + ' input[name="' + key + '"]').val(
        value
      );
      if (
        $("form#" + formNameForSetFormData + " input[name=" + key + "]").attr(
          "type"
        ) == "date"
      ) {
        $("form#" + formNameForSetFormData + " input[name=" + key + "]").val(
          moment(value).format("YYYY-MM-DD")
        );
      }
    }
    if (
      $("form#" + formNameForSetFormData + " input[name=" + key + "]").attr(
        "type"
      ) == "color"
    ) {
      if (value) {
        $("form#" + formNameForSetFormData + " input[name=" + key + "]").val(
          value
        );
      } else {
        $("form#" + formNameForSetFormData + " input[name=" + key + "]").val(
          "#ffffff"
        );
      }
    }
    try {
      if (
        $("form#" + formNameForSetFormData + " input[name=" + key + "]").is(
          ":checkbox"
        ) &&
        $(
          "form#" + formNameForSetFormData + " input[name=" + key + "]"
        ).val() === value
      ) {
        $("form#" + formNameForSetFormData + " input[name=" + key + "]").attr(
          "checked",
          "checked"
        );
      }
    } catch (e) {}
    if (
      !$(
        "form#" + formNameForSetFormData + ' textarea[name="' + key + '"]'
      ).val() ||
      skipDataIsSetCheck
    ) {
      $("form#" + formNameForSetFormData + ' textarea[name="' + key + '"]').val(
        value
      );
    }
    $("form#" + formNameForSetFormData + " select[name=" + key + "]")
      .val(value)
      .trigger("change");
    if (
      !$(
        "form#" + formNameForSetFormData + " select[name=" + key + "]"
      ).val() ||
      skipDataIsSetCheck
    ) {
      $("form#" + formNameForSetFormData + " select[name=" + key + "]").val(
        value
      );
    }
    $("form#" + formNameForSetFormData + " select[name='" + key + "[]']")
      .val(value)
      .trigger("change");

    $("form#" + formNameForSetFormData + ' input:radio[name="' + key + '"]')
      .filter('[value="' + value + '"]')
      .attr("checked", true);
  });
};

$("input").attr("autocomplete", "nope");
$("textarea").attr("autocomplete", "nope");
$(document).ready(function () {
  $("form").attr("autocomplete", "nope");
  $("<input>")
    .attr({
      type: "hidden",
      value: parent.location.href,
      name: "current_url",
    })
    .appendTo("form");
  if (window?.global_form_session?.global_from_input) {
    $.each(window.global_form_session.global_from_input, function (key, value) {
      $("input[name=" + key + "]")
        .autocomplete({
          source: value,
          minLength: 0,
        })
        .on("focus", function () {
          $(this).autocomplete("search", "");
        });
      $("textarea[name=" + key + "]")
        .autocomplete({
          source: value,
          minLength: 0,
        })
        .on("focus", function () {
          $(this).autocomplete("search", "");
        });

      $("input[name=" + key + "]").attr("autocomplete", "nope");
      $("textarea[name=" + key + "]").attr("autocomplete", "nope");
      $("input").attr("autocomplete", "nope");
      $("textarea").attr("autocomplete", "nope");
    });
  }
});
